




















































































































































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import {
	columnCurrencyFormatter,
	getFormattedCurrency,
} from "@/utils/CommonUtils";
import { ColDef, ColGroupDef } from "ag-grid-community";
import axios from "@/utils/ApiUtils";
import {
	BpayInformation,
	DirectDebitInformation,
	EftInformation,
	PaymentInformationDto,
} from "@/models/PaymentInformation";
import TextField from "@/form/TextField.vue";
import FieldGroup from "@/form/FieldGroup.vue";
import Grid from "@/grid/Grid.vue";
import { parseErrorMessage, parseErrorMessageList } from "@/utils/ErrorUtils";
import SubHeading from "@/components/SubHeading.vue";
import BpayHelpText from "@/components/BpayHelpText.vue";
import AlertInfo from "@/components/AlertInfo.vue";
import Container from "@/components/Container.vue";
import {
	dbPaymentInfoUrl,
	paymentInformationCheckURL,
} from "@/constants/apiconstants";
import ErrorList from "@/components/ErrorList.vue";
import { PaymentInformation } from "@/models/FinalBatchReviewRecord";
import { dbPaymentColumnDefs } from "@/pages/contribution/FinalBatchReviewPage.vue";

@Component({
	components: {
		AlertInfo,
		BpayHelpText,
		TextField,
		FieldGroup,
		Grid,
		SubHeading,
		Container,
		ErrorList,
	},
	props: {
		batchId: Number,
		showPayment: Boolean,
		DBBypassFlag: Boolean,
	},
})
export default class PaymentDetail extends Vue {
	@Prop({ type: Number }) private batchId!: number;

	@Prop({ type: Boolean, default: false })
	private showPayment!: boolean;

	@Prop({ type: Boolean })
	public DBBypassFlag!: boolean;

	private isMultiple: boolean | null = null;

	readonly columnDefsBpay: (ColGroupDef | ColDef)[] = [
		{ headerName: "Sub batch ID", field: "subBatchId", maxWidth: 100 },
		{
			headerName: "Reporting centre",
			field: "reportingCentreName",
			minWidth: 250,
		},
		{ headerName: "ABN", field: "abn", maxWidth: 130 },
		{
			headerName: "Amount",
			field: "amount",
			valueFormatter: columnCurrencyFormatter,
			maxWidth: 110,
		},
		// { headerName: "Method", field: "paymentMethod" }, // changing the payment method per row is not MVP
		{ headerName: "Biller code", field: "billerCode", maxWidth: 140 },
		{
			headerName: "Customer reference number",
			field: "paymentReferenceNumber",
		},
		{
			headerName: "Employer contribution received",
			field: "employerContributionReceived",
			hide: !this.showPayment,
		},
	];

	readonly columnDefsEft: (ColGroupDef | ColDef)[] = [
		{ headerName: "Sub batch ID", field: "subBatchId", maxWidth: 100 },
		{
			headerName: "Reporting centre",
			field: "reportingCentreName",
			minWidth: 230,
		},
		{ headerName: "ABN", field: "abn", maxWidth: 130 },
		{
			headerName: "Amount",
			field: "amount",
			valueFormatter: columnCurrencyFormatter,
			maxWidth: 110,
		},
		{ headerName: "BSB", field: "bsb", maxWidth: 110 },
		{ headerName: "Account number", field: "account", maxWidth: 140 },
		{ headerName: "Account name", field: "accountName" },
		{
			headerName: "Employer contribution received",
			field: "employerContributionReceived",
			hide: !this.showPayment,
		},
	];

	private rowDataBpay: BpayInformation[] | null = null;
	private rowDataEft: EftInformation[] | null = null;
	private rowDataDd: DirectDebitInformation[] | null = null;
	private paymentInformation: PaymentInformationDto | null = null;
	private dbPaymentInformation: PaymentInformation[] | null = null;

	private error: string | null = null;
	private authoriseError: string[] | null = null;

	mounted() {
		let paymentInformation: PaymentInformationDto | undefined;
		axios
			.get<PaymentInformationDto>(
				"api/contribution/payment/" + this.batchId,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			)
			.then((resp) => {
				paymentInformation = resp.data;
				if (paymentInformation) {
					this.paymentInformation = paymentInformation;
					this.isMultiple =
						paymentInformation.bpay.length > 1 ||
						paymentInformation.eft.length > 1 ||
						paymentInformation.directDebit.length > 1;
				}
			})
			.catch((error) => {
				this.error = parseErrorMessage(error);
			});
		this.paymentDetailCheck(this.batchId);

		axios
			.get<PaymentInformation[]>(dbPaymentInfoUrl(this.batchId))
			.then((resp) => {
				this.dbPaymentInformation = resp.data;
			});
	}

	private paymentDetailCheck(id: number) {
		axios.get(paymentInformationCheckURL(Number(id))).catch((e: any) => {
			if (e.response.status !== 403) {
				this.authoriseError = parseErrorMessageList(e);
			}
		});
	}

	/**
	 * Single record amount
	 * Total amount not needed for multiple records
	 */
	private get totalAmountBpay(): string {
		if (this.paymentInformation === null || this.isMultiple) {
			return "";
		}

		const amount = this.paymentInformation.directDebitFlag
			? this.paymentInformation.directDebit[0].amount
			: this.paymentInformation.bpay[0].amount;
		return getFormattedCurrency(amount);
	}

	private get singleReportingCentreName(): string {
		if (this.paymentInformation === null || this.isMultiple) {
			return "";
		}

		return this.paymentInformation.directDebitFlag
			? this.paymentInformation.directDebit[0].reportingCentreName
			: this.paymentInformation.bpay[0].reportingCentreName;
	}
	private get singleEmployerName(): string {
		if (this.paymentInformation === null || this.isMultiple) {
			return "";
		}

		return this.paymentInformation.directDebitFlag
			? this.paymentInformation.directDebit[0].employerName
			: this.paymentInformation.bpay[0].employerName;
	}
	private get singleAbn(): string {
		if (this.paymentInformation === null || this.isMultiple) {
			return "";
		}

		return this.paymentInformation.directDebitFlag
			? this.paymentInformation.directDebit[0].abn
			: this.paymentInformation.bpay[0].abn;
	}

	get dbPaymentColumnDefs() {
		return dbPaymentColumnDefs;
	}

	private onGridReadyBpay() {
		if (!this.paymentInformation) {
			return;
		}
		this.rowDataBpay = this.paymentInformation.bpay;
	}

	private onGridReadyEft() {
		if (!this.paymentInformation) {
			return;
		}
		this.rowDataEft = this.paymentInformation.eft;
	}

	private onGridReadyDd() {
		if (!this.paymentInformation) {
			return;
		}
		this.rowDataDd = this.paymentInformation.directDebit;
	}
}
