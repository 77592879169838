

































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import TextField from "@/form/TextField.vue";
import FieldGroup from "@/form/FieldGroup.vue";
import Button from "@/form/Button.vue";
import { CompanyDetail, RCStrategyTypes } from "@/pages/maintenanceTypes";
import SubHeading from "@/components/SubHeading.vue";
import SelectField from "@/form/SelectField.vue";
import { RadioOption } from "@/form/FieldOptions";
import RadioGroup from "@/form/RadioGroup.vue";
import { hasPermission } from "@/utils/PermissionUtils";

export const splitErrorBatchOptions: RadioOption[] = [
	{
		value: "",
		text: "Default",
	},
	{
		value: "false",
		text: "No",
	},
	{
		value: "true",
		text: "Yes",
	},
];
@Component({
	components: {
		Layout,
		TextField,
		FieldGroup,
		Button,
		SubHeading,
		SelectField,
		RadioGroup,
	},
})
export default class CompanyDetails extends Vue {
	@Prop() readonly companyDetail!: CompanyDetail | null;
	@Prop(Boolean) readonly readonly!: boolean;
	readonly configDateFormats = [
		{
			label: "yyyy-MM-dd",
			value: "yyyy-M-d",
		},
		{
			label: "yy-MMM-dd",
			value: "yy-MMM-d",
		},
		{
			label: "yy-MM-dd",
			value: "yy-M-d",
		},
		{
			label: "yyyy-MMM-dd",
			value: "yyyy-MMM-dd",
		},
		{
			label: "yyyy MMM dd",
			value: "yyyy MMM dd",
		},
		{
			label: "yyyy/MM/dd",
			value: "yyyy/M/d",
		},
		{
			label: "yy/MM/dd",
			value: "yy/M/d",
		},
		{
			label: "MMM-dd-yy",
			value: "MMM-d-y",
		},
		{
			label: "MM-dd-yyyy",
			value: "M-d-yyyy",
		},
		{
			label: "MM-dd-yy",
			value: "M-d-yy",
		},
		{
			label: "MMM-dd-yyyy",
			value: "MMM-d-yyyy",
		},
		{
			label: "MMM dd yyyy",
			value: "MMM d yyyy",
		},
		{
			label: "MM/dd/yyyy",
			value: "M/d/yyyy",
		},
		{
			label: "MM/dd/yy",
			value: "M/d/yy",
		},
		{
			label: "dd-MMM-yy",
			value: "d-MMM-yy",
		},
		{
			label: "dd-MM-yyyy",
			value: "d-M-yyyy",
		},
		{
			label: "dd-MM-yy",
			value: "d-M-yy",
		},
		{
			label: "dd-MMM-yyyy",
			value: "d-MMM-yyyy",
		},
		{
			label: "dd MMM yyyy",
			value: "d MMM yyyy",
		},
		{
			label: "dd/MM/yyyy",
			value: "d/M/yyyy",
		},
		{
			label: "dd/MM/yy",
			value: "d/M/yy",
		},
	];

	readonly configReportingCentreIdentification = [
		{
			label: "Employer ABN + Payer Account Number + Payer BSB",
			value: RCStrategyTypes.ABN_BK,
		},
		{
			label: "Employer ABN + Location ID",
			value: RCStrategyTypes.ABN_LC,
		},
		{
			label: "Employer ABN",
			value: RCStrategyTypes.ABN,
		},
	];

	private readonly enableSplitBatchOptions: RadioOption[] =
		splitErrorBatchOptions;

	emitUpdate(field: string, value: string) {
		this.$emit("change", { field: field, value: value });
	}

	get showSplitErrorBatchOption() {
		return hasPermission("EDIT_EMPLOYER_SPLIT_ERROR_BATCH");
	}
}
