







































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import Button from "@/form/Button.vue";
import CompanyDetails from "@/components/CompanyDetails.vue";
import axios from "@/utils/ApiUtils";
import {
	saveParent,
	searchParent,
	employerABNsForParent,
} from "@/constants/apiconstants";
import {
	ParentOrgDto,
	RoleOption,
	UpdateFieldValue,
} from "@/pages/maintenanceTypes";
import {
	toastErrorMessage,
	toastInfoMessage,
	toastSuccessMessage,
} from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import AuthContact from "@/components/AuthContact.vue";
import { VForm } from "@/@typings/type-vee-validate";
import { isEqual } from "lodash-es";
import ModalWithSaveAndCancel from "@/components/ModalWithSaveAndCancel.vue";
import { hasPermission } from "@/utils/PermissionUtils";
import SelectField from "@/form/SelectField.vue";
import SubHeading from "@/components/SubHeading.vue";
import { CheckBoxGroupOption, RadioOption } from "@/form/FieldOptions";
import RadioGroup from "@/form/RadioGroup.vue";

import { PortalUser } from "@/models/PortalUser";
import { EmployerHierarchy } from "@/store/modules/persistent/persistentTypes";
import store from "@/store";
import { splitErrorBatchOptions } from "@/components/CompanyConfigDetails.vue";

/**
 * This should correspond to the Java class au.com.iress.clearinghouse.portal.user.UserRestService.UserResponse
 */
interface UserResponse {
	user: PortalUser;
	roles: RoleOption[];
	employerTreeHierarchy: EmployerHierarchy[];
	userRoleIds: number[];
	entities: string[];
	canEditRoles: boolean;
	userSponsorIds: number[];
	sponsors: CheckBoxGroupOption[];
}

@Component({
	components: {
		SelectField,
		AuthContact,
		CompanyDetails,
		Button,
		ModalWithSaveAndCancel,
		SubHeading,
		RadioGroup,
	},
})
export default class EditParentOrganisation extends Vue {
	private storedCompanyDetail: ParentOrgDto | null = null;
	private companyDetail: ParentOrgDto | null = null;
	private showCancelModal = false;

	private overrideThresholdAlwaysShowInsightsMenuOptions: RadioOption[] = [
		{
			value: "false",
			text: "Use employee count thresholds",
		},
		{
			value: "true",
			text: "Override employee count (always show Insights)",
		},
	];

	private readonly enableRefundsV2ReportOptions: RadioOption[] = [
		{
			value: "false",
			text: "Refunds V2 Report will not be shown in Reports menu",
		},
		{
			value: "true",
			text: "Refunds V2 Report will be enabled in Reports menu",
		},
	];

	private readonly enableSplitBatchOptions: RadioOption[] =
		splitErrorBatchOptions;

	/**
	 * Indicate whether form is editing and contains changes
	 */
	private isFormDirty = false;

	@Prop({ type: Number, required: true, default: null })
	readonly parentOrganisationId!: number | null;

	readonly userCanConfigureOverrideInsights: boolean =
		this.shouldUserBeAbleToConfigureOverrideInsights();

	inputOverrideInsightsMenuEmployeeThreshold(event: any) {
		if (this.companyDetail) {
			if (event && event == "true") {
				this.companyDetail.overrideInsightsMenuEmployeeThreshold = true;
			} else {
				this.companyDetail.overrideInsightsMenuEmployeeThreshold =
					false;
			}
		}
	}

	shouldUserBeAbleToConfigureOverrideInsights(): boolean {
		const isIressOperations = store.getters["persistent/iressOperations"];

		return isIressOperations;
	}

	async saveParent() {
		if (
			this.storedCompanyDetail &&
			isEqual(this.storedCompanyDetail, this.companyDetail)
		) {
			toastInfoMessage(
				"No changes have been made to this record, save not required"
			);
			return;
		}

		if (await (this.$refs.parentForm as VForm).validate()) {
			const companyDetail = this.companyDetail;
			delete companyDetail?.authorisationPreferenceOptions;
			if (this.sensitiveReadOnly) {
				delete companyDetail?.authPreference;
			}
			axios
				.put(saveParent(), companyDetail)
				.then((response) => {
					if (this.companyDetail) {
						this.storedCompanyDetail = { ...this.companyDetail };
					}
					this.isFormDirty = false;
					this.$emit("save");
					toastSuccessMessage(
						"Parent Organisation saved successfully"
					);
					if (this.parentOrganisationId) {
						this.fetchCompanyDetail(this.parentOrganisationId);
					}
				})
				.catch((error) => {
					toastErrorMessage(parseErrorMessage(error));
				});
		}
	}

	addNewEmployer() {
		if (this.parentOrganisationId) {
			this.$router.push({
				name: "Add Employer",
				params: {
					parentOrganisationId: this.parentOrganisationId.toString(),
				},
			});

			this.getEmployerABNs(this.parentOrganisationId);
		}
	}

	get isCompanyDetailChanged() {
		return (
			this.storedCompanyDetail !== null &&
			!isEqual(this.companyDetail, this.storedCompanyDetail)
		);
	}

	updateCompanyDetail(e: UpdateFieldValue) {
		if (this.companyDetail) {
			Vue.set(this.companyDetail, e.field, e.value);

			if (!this.isFormDirty && this.isCompanyDetailChanged) {
				this.isFormDirty = true;
				this.$emit("form-changed");
			}
		}
	}

	private discardChanges() {
		this.isFormDirty = false;
		this.$emit("cancel");
	}

	private closeModal() {
		this.showCancelModal = false;
	}

	private cancel() {
		if (this.isCompanyDetailChanged) {
			this.showCancelModal = true;
		} else {
			this.discardChanges();
		}
	}

	@Watch("parentOrganisationId", { immediate: true })
	fetchCompanyDetailsOnChange(value: number | null) {
		if (value !== null) {
			this.fetchCompanyDetail(value);
			this.getEmployerABNs(value);
		} else {
			this.companyDetail = null;
		}
	}

	private async getEmployerABNs(parentOrganisationId: number): Promise<void> {
		await axios
			.get(employerABNsForParent(parentOrganisationId))
			.then((response) => {
				const existingEmployerABNsForParentOrganisation = response.data;

				localStorage.setItem(
					"ParentOrgABNs",
					existingEmployerABNsForParentOrganisation
				);
			})
			.catch((error) => {
				if (error.response.status === 403) {
					toastErrorMessage(
						"Your current permissions do not allow you to view this data."
					);
				} else {
					toastErrorMessage(parseErrorMessage(error));
				}
			});
	}

	private async fetchCompanyDetail(
		parentOrganisationId: number
	): Promise<void> {
		await axios
			.get(searchParent(parentOrganisationId))
			.then((response) => {
				this.companyDetail = response.data.data as ParentOrgDto;

				if (
					!this.companyDetail.overrideInsightsMenuEmployeeThreshold ||
					this.companyDetail.overrideInsightsMenuEmployeeThreshold ==
						null
				) {
					this.companyDetail.overrideInsightsMenuEmployeeThreshold =
						false;
				}

				this.storedCompanyDetail = { ...this.companyDetail };
				if (this.storedCompanyDetail.sponsor) {
					this.$emit("sponsor", this.storedCompanyDetail.sponsor);
				}
			})
			.catch((error) => {
				if (error.response.status === 403) {
					toastErrorMessage(
						"Your current permissions do not allow you to view this data."
					);
				} else {
					toastErrorMessage(parseErrorMessage(error));
				}
			});
	}

	get overrideEmployeeThresholdInsightsPermission() {
		return hasPermission("EDIT_OVERRIDE_SHOW_INSIGHTS");
	}

	get showRefundsV2Option() {
		return hasPermission("EDIT_PARENT_REFUND_V2");
	}

	get showSplitErrorBatchOption() {
		return hasPermission("EDIT_PARENT_SPLIT_ERROR_BATCH");
	}

	get readonly() {
		return !hasPermission("EDIT_EMPLOYER");
	}

	get readonlyAbn() {
		return !hasPermission("EDIT_EMPLOYER_ABN");
	}
	get sensitiveReadOnly() {
		return !hasPermission("EDIT_PARENT_SENSITIVE");
	}
}
