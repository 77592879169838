var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"EditFieldErrorWarningList"},[(_vm.shouldShowErrorList)?[_c('h1',{staticClass:"h4 mb-2 error-text"},[_vm._v(" Errors ")]),_c('ul',{staticClass:"EditFieldErrorWarningList__list"},_vm._l((_vm.errorFields),function(field,fieldName){return _c('li',{key:fieldName,staticClass:"EditFieldErrorWarningList__list__field",class:{
					'EditFieldErrorWarningList__list__field--error':
						fieldName === _vm.highlightedFieldName,
				},on:{"mouseenter":function($event){return _vm.emitHighlightedChange(fieldName, 'error')},"mouseleave":function($event){return _vm.emitHighlightedChange('', '')}}},[_c('h2',{staticClass:"EditFieldErrorWarningList__list__field__name error-text"},[_c('ErrorIcon'),_vm._v(" "+_vm._s(field.label)+" ")],1),_c('ul',_vm._l((field.errors),function(errorMessage){return _c('li',{key:errorMessage,staticClass:"EditFieldErrorWarningList__list__field__message"},[_vm._v(" "+_vm._s(_vm.getFieldErrorMessage(errorMessage, field.label))+" ")])}),0)])}),0)]:_vm._e(),(_vm.shouldShowWarningList)?[_c('h1',{staticClass:"h4 mb-2 warning-text"},[_vm._v(" Warnings ")]),_c('ul',{staticClass:"EditFieldErrorWarningList__list"},_vm._l((_vm.warningFields),function(field,fieldName){return _c('li',{key:fieldName,class:{
					EditFieldErrorWarningList__list__field: true,
					'EditFieldErrorWarningList__list__field--warning':
						fieldName === _vm.highlightedFieldName,
				},on:{"mouseenter":function($event){return _vm.emitHighlightedChange(fieldName, 'warning')},"mouseleave":function($event){return _vm.emitHighlightedChange('', '')}}},[_c('h2',{staticClass:"EditFieldErrorWarningList__list__field__name warning-text"},[_c('WarningIcon'),_vm._v(" "+_vm._s(field.label)+" ")],1),_c('ul',_vm._l((field.warnings),function(warningMessage){return _c('li',{key:warningMessage,staticClass:"EditFieldErrorWarningList__list__field__message"},[_vm._v(" "+_vm._s(warningMessage)+" ")])}),0)])}),0)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }