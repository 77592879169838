









































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import TextareaField from "@/form/TextareaField.vue";
import { formatDateTime } from "@/utils/CommonUtils";
import { MessageDTO } from "@/pages/FundMessagePage.vue";
import Button from "@/form/Button.vue";

@Component({
	components: {
		Button,
		TextareaField,
	},
})
export default class FundMessageThread extends Vue {
	@Prop({ type: Array, default: () => [] })
	private readonly messages!: MessageDTO[];

	private readonly formatDateTime = formatDateTime;
	private readonly emitDownload = "download";
	private readonly emitBack = "back";
	@Prop({ type: Boolean, default: false })
	public disabled!: boolean;

	private truncateName = (name: string) => {
		if (name.length > 20) {
			return name.substring(0, 20) + "...";
		}
		return name;
	};
}
