


















































































































































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import Grid from "@/grid/Grid.vue";
import SelectField from "@/form/SelectField.vue";
import EmployerSelector from "@/components/EmployerSelector.vue";
import TextField from "@/form/TextField.vue";
import DatepickerField from "@/form/DatepickerField.vue";
import { toastErrorMessage, toastSuccessMessage } from "@/plugins/toasts";
import {
	ColDef,
	ColGroupDef,
	ICellRendererParamsTyped,
	IServerSideDatasourceTyped,
	IServerSideGetRowsParamsTyped,
	SelectionChangedEvent,
	ValueGetterParamsTyped,
} from "ag-grid-community";
import axios, { axiosStatic } from "@/utils/ApiUtils";
import { EmployerHierarchy } from "@/store/modules/persistent/persistentTypes";
import {
	getAlertsListURL,
	getMessageListURL,
	updateNotificationDone,
	updateNotificationPending,
} from "@/constants/apiconstants";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import { FilterModel, PagedResult } from "@/grid/gridTypes";
import GridFilter from "@/components/GridFilter.vue";
import {
	ActionedBy,
	Alert,
	AlertEntityType,
	AlertSeverityLevel,
	AlertSeverityMapper,
	NotificationCategory,
	NotificationCategoryMapper,
	Status,
} from "@/models/AlertRow";
import {
	columnDateTimeFormatter,
	getDateRangeForPastThreeMonths,
} from "@/utils/CommonUtils";
import { TSelectLevel } from "@/components/employerSelectorTypes";
import { createNamespacedHelpers } from "vuex";
import { isOnlyOneChildlessEmployerSelected } from "@/utils/EmployerSelectorUtils";
import { NO_RC_ERROR_MESSAGE } from "@/constants/constants";
import GridActionsRenderer from "@/grid/GridActionsRenderer.vue";
import GridDeepLinkRenderer from "@/grid/GridDeepLinkRenderer.vue";
import AlertDetails from "@/components/AlertDetails.vue";
import { SelectOption } from "@/form/FieldOptions";
import Button from "@/form/Button.vue";
import {
	SUPERSTREAM_TYPE_CTER,
	SUPERSTREAM_TYPE_MROR,
	SuperStreamSelectedRow,
} from "@/models/SuperStreamContributionResponse";
import SuperStreamDetail from "@/components/SuperStreamDetail.vue";
import { RowNode } from "ag-grid-community/dist/lib/entities/rowNode";
import ModalWithSaveAndCancel from "@/components/ModalWithSaveAndCancel.vue";
import { commitToModule, registerModule } from "@/store/modules/filters";
import { hasPermission } from "@/utils/PermissionUtils";
import FundMessageDetail from "@/components/FundMessageDetail.vue";

const { mapState } = createNamespacedHelpers("persistent");

@Component({
	components: {
		Layout,
		Grid,
		GridFilter,
		SelectField,
		TextField,
		DatepickerField,
		EmployerSelector,
		AlertDetails,
		SuperStreamDetail,
		Button,
		ModalWithSaveAndCancel,
		FundMessageDetail,
	},
	computed: mapState([
		"selectedEntities",
		"employerHierarchy",
		"iressOperations",
		"sponsorPermissions",
		"definedBenefitEntities",
	]),
})
export default class AlertListPage
	extends Vue
	implements IServerSideDatasourceTyped
{
	private gridReady = false;
	private rowSelection = "multiple";
	private vuexStore = "alertsPage";
	private selectedRow: Alert | SuperStreamSelectedRow | null = null;
	private selectedEntities!: string[];
	private employerHierarchy!: EmployerHierarchy[];
	private iressOperations!: boolean;
	private sponsorPermissions!: [] | null;
	private definedBenefitEntities!: string[];
	private gridVMList: Vue[] = [];
	private messageOptions: SelectOption[] = [];
	private hasPermission = hasPermission;

	public $refs!: {
		gridEl: Grid;
	};

	private pageContext: TSelectLevel = "ALL";
	private errorMessage: string | null = null;

	private filterModel: FilterModel = {
		id: {
			value: "",
			column: "id",
		},
		category: {
			value: "",
			column: "category",
		},
		rowsPerPage: {
			value: "10",
			column: "rowsPerPage",
		},
		message: {
			value: "",
			column: "message",
		},
		batchId: {
			value: "",
			column: "batchId",
		},
		createdDate: {
			value: this.getDefaultCreatedDate(),
			column: "created",
		},
		alertType: {
			value: "",
			column: "severity",
		},
		status: {
			value: Status.P,
			column: "status",
		},
	};

	readonly alertTypeOptions = [
		{
			label: AlertSeverityMapper(AlertSeverityLevel.AC_REQ),
			value: AlertSeverityLevel.AC_REQ,
		},
		{
			label: AlertSeverityMapper(AlertSeverityLevel.INFO),
			value: AlertSeverityLevel.INFO,
		},
	];

	readonly rowsPerPageOption = [
		{
			label: "10",
			value: "10",
		},
		{
			label: "50",
			value: "50",
		},
		{
			label: "100",
			value: "100",
		},
	];

	readonly defaultCategoryOptions = [
		{
			label: NotificationCategoryMapper(NotificationCategory.C),
			value: NotificationCategory.C,
		},
		{
			label: NotificationCategoryMapper(NotificationCategory.ER),
			value: NotificationCategory.ER,
		},
		{
			label: NotificationCategoryMapper(NotificationCategory.F),
			value: NotificationCategory.F,
		},
		{
			label: NotificationCategoryMapper(NotificationCategory.P),
			value: NotificationCategory.P,
		},
		{
			label: NotificationCategoryMapper(NotificationCategory.S),
			value: NotificationCategory.S,
		},
		{
			label: NotificationCategoryMapper(NotificationCategory.U),
			value: NotificationCategory.U,
		},
		{
			label: NotificationCategoryMapper(NotificationCategory.FM),
			value: NotificationCategory.FM,
		},
	];

	readonly statusOptions = [
		{
			label: "All",
			value: "",
		},
		{
			label: "Done",
			value: Status.D,
		},
		{
			label: "Pending",
			value: Status.P,
		},
	];

	private isShowCheckbox = (params: RowNode) =>
		params.data.actionedBy === ActionedBy.U ||
		params.data.actionedBy === ActionedBy.F ||
		params.data.actionedBy === ActionedBy.E;

	private hasPendingSelection = false;
	private hasDoneSelection = false;

	private isBulkStatusModalShown = false;
	private isStatusPending = false;

	private readonly columnDefs: (ColGroupDef | ColDef)[] = [
		{
			minWidth: 50,
			checkboxSelection: this.isShowCheckbox,
			headerCheckboxSelection: true,
			colId: "selectAllBox",
		},
		{
			headerName: "Ref ID",
			field: "id",
			minWidth: 150,
			resizable: true,
		},
		{
			headerName: "Category",
			field: "category",
			minWidth: 150,
			resizable: true,
			valueGetter: this.categoryValue,
		},
		{
			headerName: "Batch ID",
			field: "batchId",
			minWidth: 100,
			resizable: true,
		},
		{
			headerName: "Date",
			field: "created",
			minWidth: 210,
			resizable: true,
			valueFormatter: columnDateTimeFormatter,
		},
		{
			headerName: "Actioned by",
			field: "lastActionedUser",
			minWidth: 150,
			resizable: true,
		},
		{
			headerName: "Message",
			field: "message",
			minWidth: 450,
			resizable: true,
		},
		{
			headerName: "Type",
			field: "severity",
			minWidth: 150,
			resizable: true,
			valueGetter: this.severityValue,
		},
		{
			headerName: "Status",
			field: "status",
			minWidth: 100,
			resizable: true,
			pinned: "right",
			cellRendererParams: {
				translationMappings: [
					{
						value: Status.D,
						translatedValue: "Done",
						style: "font-weight: bold;color:#228b22;", //Forest green
					},
					{
						value: Status.P,
						translatedValue: "Pending",
						style: "font-weight: bold;color:#8b0000;", //Red
					},
				],
			},
			cellRenderer: "valueTranslatedCellRenderer",
		},
		{
			headerName: "Assigned to",
			field: "messageAssignedTo",
			minWidth: 100,
			resizable: true,
			pinned: "right",
			cellRendererParams: {
				translationMappings: [
					{
						value: "E",
						translatedValue: "Employer",
					},
					{
						value: "F",
						translatedValue: "Fund",
					},
				],
			},
			cellRenderer: "valueTranslatedCellRenderer",
			hide: !hasPermission("VIEW_FUND_MESSAGE_CATEGORY"),
		},
		{
			headerName: "View/Edit",
			cellRenderer: this.actionsRender,
			minWidth: 100,
			resizable: true,
			pinned: "right",
		},
		{
			headerName: "Go to",
			cellRenderer: this.deepLinkRenderer,
			minWidth: 100,
			resizable: true,
			pinned: "right",
		},
	];

	created() {
		registerModule(this.$store, this.vuexStore, this.filterModel);
		this.filterModel = this.$store.getters[`${this.vuexStore}/filters`];
	}

	actionsRender(params: ICellRendererParamsTyped<Alert>): HTMLElement {
		const vm = new Vue({
			el: document.createElement("div"),
			render: (createElement) => {
				return createElement(GridActionsRenderer, {
					props: {
						rowIndex: params.rowIndex,
						row: params.data,
						rowId: this.getRowId(params),
						isEdit:
							params.data.actionedBy === ActionedBy.U ||
							(params.data.type === "MSSG" &&
								params.data.status !== "D"),
						isView:
							params.data.actionedBy === ActionedBy.S ||
							(params.data.type === "MSSG" &&
								params.data.status === "D"),
					},
					on: {
						clickEdit: this.onShowAlertDetails,
						clickView: this.onShowAlertDetails,
					},
				});
			},
		});
		this.gridVMList.push(vm);
		return vm.$el as HTMLElement;
	}

	deepLinkRenderer(params: ICellRendererParamsTyped<Alert>): HTMLElement {
		const vm = new Vue({
			el: document.createElement("div"),
			render: (createElement) => {
				return createElement(GridDeepLinkRenderer, {
					props: {
						link: params.data.deepLink,
						rowId: this.getRowId(params),
						router: this.$router,
					},
				});
			},
		});
		this.gridVMList.push(vm);
		return vm.$el as HTMLElement;
	}

	private onPageChanged(): void {
		this.selectedRow = null;
		if (this.$refs.gridEl?.api) {
			this.$refs.gridEl.api.deselectAll();
		}
	}

	onSelectionChanged(event: SelectionChangedEvent) {
		this.hasPendingSelection = false;
		this.hasDoneSelection = false;
		const nodes = event.api.getSelectedNodes();
		for (const node of nodes) {
			if (node.data.status === Status.P) {
				this.hasPendingSelection = true;
			} else if (node.data.status === Status.D) {
				this.hasDoneSelection = true;
			}
		}
	}

	private onGridReady(): void {
		this.gridReady = true;
	}

	private onApplyFilter(): void {
		commitToModule(this.$store, this.vuexStore, this.filterModel);
		this.reloadGrid();
	}

	private onResetFilter(): void {
		this.filterModel.id.value = "";
		this.filterModel.category.value = "";
		this.filterModel.alertType.value = "";
		this.filterModel.status.value = Status.P;
		this.filterModel.batchId.value = "";
		this.filterModel.message.value = "";
		this.filterModel.createdDate.value = this.getDefaultCreatedDate();
		this.onApplyFilter();
	}

	private onShowAlertDetails({ row }: { row: Alert }): void {
		if (
			row.entityType === AlertEntityType.SUPERSTREAM_MROR ||
			row.entityType === AlertEntityType.SUPERSTREAM_CTER
		) {
			this.selectedRow = {
				batchId: parseInt(row.batchId, 10),
				responseTypeId: row.entityKey,
				responseType:
					row.entityType === AlertEntityType.SUPERSTREAM_MROR
						? SUPERSTREAM_TYPE_MROR
						: SUPERSTREAM_TYPE_CTER,
				refId: row.id.toString(),
			};
		} else {
			this.selectedRow = { ...row };
		}
	}

	cancelBulkStatusUpdate() {
		this.isBulkStatusModalShown = false;
	}

	markSelectedAsDone() {
		if (
			this.$refs.gridEl?.api &&
			this.$refs.gridEl.api.getSelectedNodes().length > 0
		) {
			this.isStatusPending = true;
			if (this.$refs.gridEl.api.getSelectedNodes().length > 1) {
				this.isBulkStatusModalShown = true;
			} else {
				this.updateSelectedStatus();
			}
		}
	}

	markSelectedAsPending() {
		if (
			this.$refs.gridEl?.api &&
			this.$refs.gridEl.api.getSelectedNodes().length > 0
		) {
			this.isStatusPending = false;
			if (this.$refs.gridEl.api.getSelectedNodes().length > 1) {
				this.isBulkStatusModalShown = true;
			} else {
				this.updateSelectedStatus();
			}
		}
	}

	updateSelectedStatus() {
		const url = this.isStatusPending
			? updateNotificationDone()
			: updateNotificationPending();

		if (!this.$refs.gridEl?.api) {
			return;
		}

		const records = this.$refs.gridEl.api.getSelectedNodes().map((node) => {
			return {
				id: node.data.id,
				type: node.data.type,
			};
		});

		axios
			.put(url, records)
			.then(() => {
				this.isBulkStatusModalShown = false;
				toastSuccessMessage("Updated successfully.");
				this.reloadGrid();
			})
			.catch((rejected) => {
				toastErrorMessage(parseErrorMessage(rejected));
			});
	}

	createNewFundMessage() {
		this.$router.push({
			name: "Fund message",
			params: { createView: "create", id: "0" },
		});
	}

	private onDone(): void {
		this.reloadGrid();
	}

	private onPending(): void {
		this.reloadGrid();
	}

	private onClose(): void {
		this.selectedRow = null;
	}

	private clearErrorMessage(): void {
		this.errorMessage = null;
	}

	get selectedReportingCenter() {
		return this.$store.state.persistent.selectedEntities;
	}

	get displayCategoryOptions() {
		let categoryOptions = this.defaultCategoryOptions;
		if (!hasPermission("VIEW_EMPLOYER_REGISTRATION")) {
			categoryOptions = categoryOptions.filter(
				(cat) => cat.value !== NotificationCategory.ER
			);
		}

		/*
			hide fund messages from alerts page, if the user doesn't have the permission.
			Iress user's will be able to see + filter these alerts
		*/
		if (!hasPermission("VIEW_FUND_MESSAGE_CATEGORY")) {
			categoryOptions = categoryOptions.filter(
				(cat) => cat.value !== NotificationCategory.FM
			);
		}
		return categoryOptions;
	}

	@Watch("selectedReportingCenter")
	private onSelectedReportingCenterChanged() {
		this.retrieveNotifications();
	}

	private retrieveNotifications(): void {
		this.reloadGrid();
	}

	private reloadGrid(): void {
		this.gridReady = false;
		this.selectedRow = null;
		if (!this.$refs.gridEl) {
			return;
		}
		this.$refs.gridEl.reload();
		this.$refs.gridEl.api?.deselectAll();
		this.gridReady = true;
	}

	private getMessageList(): void {
		axios.get<SelectOption[]>(getMessageListURL()).then((response) => {
			let options = [...response.data];
			if (!hasPermission("VIEW_FUND_MESSAGE_CATEGORY")) {
				options = options.filter((cat) => cat.value !== "MSSG");
			}
			this.messageOptions = options;
		});
	}

	getRows(params: IServerSideGetRowsParamsTyped<Alert>): void {
		if (
			isOnlyOneChildlessEmployerSelected(
				this.selectedEntities,
				this.employerHierarchy
			)
		) {
			this.errorMessage = NO_RC_ERROR_MESSAGE;
			params.successCallback([]);
			return;
		}

		params.request.filterModel = Object.keys(this.filterModel).map(
			(key) => {
				return this.filterModel[key];
			}
		);

		axios
			.get<PagedResult<Alert>>(getAlertsListURL(), {
				params: {
					// Pass in <Grid> parameters
					grid: params.request,
					entities: this.$store.state.persistent.selectedEntities,
				},
				cancelToken: params.cancelToken,
			})
			.then((response) => {
				params.successCallback(response.data);
			})
			.catch((error) => {
				if (axiosStatic.isCancel(error)) {
					return;
				}
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	constructor() {
		super();
	}

	beforeMount() {
		this.getMessageList();
	}

	private getDefaultCreatedDate(): string {
		return getDateRangeForPastThreeMonths();
	}

	private getRowId(params: ICellRendererParamsTyped<Alert>) {
		switch (params.data.entityType) {
			case AlertEntityType.SUPERSTREAM_MROR:
				return "row" + SUPERSTREAM_TYPE_MROR + params.data.entityKey;
			case AlertEntityType.SUPERSTREAM_CTER:
				return "row" + SUPERSTREAM_TYPE_CTER + params.data.entityKey;
			default:
				return "row" + params.data.id;
		}
	}
	private severityValue(params: ValueGetterParamsTyped<Alert>) {
		return AlertSeverityMapper(params.data.severity);
	}

	private categoryValue(params: ValueGetterParamsTyped<Alert>) {
		return NotificationCategoryMapper(params.data.category);
	}
}
