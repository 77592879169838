export interface APIRequest {
	headers?: {};
	method?: "";
	url?: string;
}

// -----------------------------------------------------------------------------
// API Root
// -----------------------------------------------------------------------------

const apiPort =
	process.env.NODE_ENV === "production" ? window.location.port : 8080;
export const getSiteRoot = () =>
	`${window.location.protocol}//${window.location.hostname}:${apiPort}`;

export const apiRoot = "/api";

export const getAPIRoot = () => "/api";

export const getClientId = () => "iress-clearinghouse-portal";
//Authentication
export const loginPreloginURL = () => "/prelogin";

export const loginOAuthTokenURL = () =>
	"/oauth/token?grant_type=password&client_id=" + getClientId();

export const requestRecaptchaSiteKeyUrl = "/recaptchaSiteKey";

export const requestResetPasswordURL = () => "/request_resetpassword";

export const resetPaswordURL = () => "/reset_password";

export const resendTwoFAURL = loginOAuthTokenURL;

export const revokeTokenURL = (token: string) => "/revoke/" + token;

export const refreshTokenURL = () =>
	"/oauth/token?grant_type=refresh_token&client_id=" + getClientId();

// User
export const currentUserURL = () => getAPIRoot() + "/current_user";

// Brands
export const currentBrandsListURL = () => getAPIRoot() + "/brands";

// Portal users
export const portalUserListURL = () => getAPIRoot() + "/users";
export const portalRolesListURL = () => getAPIRoot() + "/roles";
export const portalSponsorsListURL = () => getAPIRoot() + "/sponsors";
export const portalUserURL = (userId: number) =>
	`${getAPIRoot()}/users/${userId}`;

//Employee
export const employeeListURL = () => getAPIRoot() + "/employees";
export const employeeDetails = (reportingCentreId: number, id: number) =>
	`${getAPIRoot()}/employees/${reportingCentreId}/${id}`;
export const relatedFundsURL = () => getAPIRoot() + "/employees/fundlist";
export const countryListURL = () => `${getAPIRoot()}/description/countrycodes`;

//batch
export const batchListURL = () => getAPIRoot() + "/batch";
export const batchDetailURL = (id: number) =>
	`${getAPIRoot()}/batch/details/${id}`;
export const batchDetailSummaryURL = (id: number) =>
	`${getAPIRoot()}/batch/details/summary/${id}`;
export const batchCancelURL = (id: number) =>
	`${getAPIRoot()}/contribution/${id}`;
export const batchDownloadErrorReport = (batchId: number) =>
	`${getAPIRoot()}/contribution/data-upload/${batchId}/errors`;
export const batchReadyForAuthoriseURL = (batchId: number) =>
	`${getAPIRoot()}/batch/${batchId}/ready-for-authorise`;

export const batchReadyForDeclineURL = (batchId: number) =>
	`${getAPIRoot()}/batch/${batchId}/ready-for-decline`;

export const batchFileLoadSummary = (batchId: number) =>
	`${getAPIRoot()}/batch/${batchId}/file-load-summary`;

export const canViewBatchURL = (batchId: number) =>
	`${getAPIRoot()}/contribution/canViewContribution/${batchId}`;

export const paymentInformationCheckURL = (batchId: number) =>
	`${getAPIRoot()}/batch/${batchId}/payment-information-check`;

export const authoriserCheckURL = (batchId: number) =>
	`${getAPIRoot()}/batch/${batchId}/authoriser-check`;

export const userHasAuthoriseBatchPermissionURL = (batchId: number) =>
	`${getAPIRoot()}/batch/${batchId}/authorise`;

export const declineBatchURL = (batchId: number) =>
	`${getAPIRoot()}/contribution/authorise/${batchId}/decline`;

export const authoriseBatchURL = (batchId: number) =>
	`${getAPIRoot()}/contribution/authorise/${batchId}`;

export const submitForApprovalURL = (batchId: number) =>
	`${getAPIRoot()}/contribution/authorise/${batchId}/request`;

export const projectedPaymentInfoUrl = (batchId: number) =>
	`${getAPIRoot()}/contribution/projected-payment/${batchId}`;
export const dbPaymentInfoUrl = (batchId: number) =>
	`${getAPIRoot()}/contribution/db-batch-payment/${batchId}`;

export const createBatchURL = () => `${getAPIRoot()}/create-batch`;

export const downloadAllContribution = (batchId: number) =>
	`${getAPIRoot()}/contribution/validate-contents/${batchId}/download`;

export const downloadAllUpdates = (batchId: number) =>
	`${getAPIRoot()}/contribution/batch-updates/${batchId}/download`;
export const downloadAllRemovedFields = (batchId: number) =>
	`${getAPIRoot()}/contribution/batch-removed/${batchId}/download`;
export const getFundsByBatch = (batchId: number) =>
	`${getAPIRoot()}/contribution/fund-options/${batchId}`;
export const getReviewAuthorisedUnfundedBatches = (batchId: number) =>
	`${getAPIRoot()}/contribution/review-batches/${batchId}`;
export const uploadContributionFileFormatId = (fileFormatId: string) =>
	`${getAPIRoot()}/contribution/file-format-id/${fileFormatId}`;
export const splitBatch = (batchId: number) =>
	`${getAPIRoot()}/contribution/${batchId}/split-batch`;
export const submitForFundValidation = (batchId: number) =>
	`${getAPIRoot()}/contribution/${batchId}/submit-to-fund`;

// News
export const newsURL = () => getAPIRoot() + "/news";

//fund
export const searchFundURL = () => getAPIRoot() + "/fund/search";
export const getDefaultApraFundsForRCURL = (id: number) =>
	`${getAPIRoot()}/fund/getDefaultApraFundsForRC/${id}`;
export const getFundsForEmployee = (reportingCentreId: number, id: number) =>
	`${getAPIRoot()}/fund/get-funds-for-employee/${reportingCentreId}/${id}`;
export const canAddDefaultFundUrl = (id: number) =>
	`${getAPIRoot()}/fund/add-default-fund/${id}`;
export const addFundURL = () => getAPIRoot() + "/fund/add-fund";
export const addSmsfURL = () => getAPIRoot() + "/fund/add-smsf";
export const getFundsForRCURL = (id: number) => `${getAPIRoot()}/fund/${id}`;
export const searchFundsForRCURL = () => `${getAPIRoot()}/fund/searchByRC`;
export const searchFundsForPayeeCodeAndRC = (
	payeeCode: string,
	fundType: string,
	rcId: number
) =>
	`${getAPIRoot()}/fund/searchFundByPayeeCodeAndRC/${payeeCode}/${fundType}/${rcId}`;
export const editFundUrl = () => getAPIRoot() + "/fund/edit-fund";
export const editSmsfUrl = () => getAPIRoot() + "/fund/edit-smsf";

//fund field mapping
export const getAllFundFieldMappingUrl = (parentBatchId: number) =>
	getAPIRoot() + `/fund-field-mapping/all/${parentBatchId}`;
export const getFundFieldMappingUrlByRcAndUsi = (rcId: number, usi: string) =>
	getAPIRoot() + `/fund-field-mapping/${rcId}/${usi}`;

// Reports
export const reportsURL = () => getAPIRoot() + "/reports";
export const definedBenefitReport = () =>
	`${getAPIRoot()}/defined-benefit/report`;

// Search employees
export const searchEmployeesURL = () => getAPIRoot() + "/employees/search";
export const searchEmployeesInABatchURL = (id: number) =>
	`${getAPIRoot()}/batch/${id}/search-employees`;

// reportingCentreDetails
export const reportingCentreDetailsURL = (id: number) =>
	`${getAPIRoot()}/reporting-centre/${id}`;
// Edit Parent data
export const searchParent = (id: number) =>
	`${getAPIRoot()}/parentorganisation/${id}`;
// Edit employer data
export const searchEmployer = (id: number) => `${getAPIRoot()}/employer/${id}`;
// Save Parent
export const saveParent = () => getAPIRoot() + "/parentorganisation/";
// Save Employer
export const saveEmployer = () => getAPIRoot() + "/employer/";
// Employer ABNs
export const employerABNsForParent = (parentOrganisationId: number) =>
	`${getAPIRoot()}/employer/abns/${parentOrganisationId}`;

// Edit reporting centre data
export const searchReportingCentre = (id: number) =>
	`${getAPIRoot()}/reporting-centre/details/${id}`;
export const retrieveReportingCentrePaymentProfile = (id: number) =>
	`${getAPIRoot()}/payment-profile/reporting-centre/${id}`;
export const retrievePaymentProfileByMemberId = (
	employerId: number,
	memberId: string,
	reportingCentreId: number
) =>
	`${getAPIRoot()}/payment-profile/employer/${employerId}/reporting-centre/${reportingCentreId}/member/${memberId}`;
export const retrieveReportingCentrePaymentProfileOptions = (id: number) =>
	`${getAPIRoot()}/payment-profile/reporting-centre/${id}/options`;
export const retrieveEmployerPaymentProfileOptions = (id: number) =>
	`${getAPIRoot()}/payment-profile/employer/${id}/options`;
export const savePaymentProfile = (id: string, reportingCentreId?: number) =>
	`${getAPIRoot()}/payment-profile/employer/${id}/reporting-centre/${reportingCentreId}`;
export const updatePaymentProfile = (
	employerId: number,
	memberId: string,
	reportingCentreId?: number
) =>
	`${getAPIRoot()}/payment-profile/employer/${employerId}/reporting-centre/${reportingCentreId}/member/${memberId}`;
// Save reporting centre
export const saveReportingCentre = () => `${getAPIRoot()}/reporting-centre`;

// Fetch Employer Tree Hierarchy
export const fetchEmployerHierarchyURL = () =>
	getAPIRoot() + "/current_user/employer-hierarchy";

// Fetch Current User Profile
export const profile = () => getAPIRoot() + "/current_user/profile";
export const userPasswordExpiring = () =>
	getAPIRoot() + "/current_user/password-expiring";
export const userNewReleaseNotesSinceLastLogin = () =>
	getAPIRoot() + "/current_user/new-release-notes";

//SuperStream Contribution
export const superStreamContributionResponseURL = (batchid: number) =>
	`${getAPIRoot()}/superstream-contribution-responses/batch/${batchid}`;
export const superStreamContributionResponseDownloadURL = (batchid: number) =>
	`${getAPIRoot()}/superstream-contribution-responses/batch/${batchid}/download`;
export const employeesWithContributions = (batchid: number) =>
	`${getAPIRoot()}/superstream-contribution-responses/batch/${batchid}/employees`;
export const employeeContributions = (batchid: number, fwId: number) =>
	`${getAPIRoot()}/superstream-contribution-responses/batch/${batchid}/employees/${fwId}`;
export const createSuperStreamCter = (batchid: number, fwId: number) =>
	`${getAPIRoot()}/superstream-contribution-responses/batch/${batchid}/employees/${fwId}/cter`;

export const superStreamContributionResponseDetailsURL = (
	batchId: number,
	responseType: string,
	id: number
) =>
	`${getAPIRoot()}/superstream-contribution-responses/batch/${batchId}/parameters/${responseType}/${id}`;

export const updateSuperStreamContributionResponseURL = (
	batchId: number,
	responseType: string,
	id: number
) =>
	`${getAPIRoot()}/superstream-contribution-responses/batch/${batchId}/parameters/${responseType}/${id}`;

//Notifications
export const getAlertsListURL = () => `${getAPIRoot()}/notification`;
export const updateNotificationDone = () =>
	`${getAPIRoot()}/notification/markDone`;
export const updateNotificationPending = () =>
	`${getAPIRoot()}/notification/markPending`;
export const getAlertsOverviewURL = () =>
	`${getAPIRoot()}/notification/overview`;
export const getAlertDetailsURL = (id: number) =>
	`${getAPIRoot()}/notification/details/${id}`;
export const getMessageListURL = () => `${getAlertsListURL()}/messageList`;
export const userPreference = () =>
	`${getAPIRoot()}/userNotificationPreference`;

//Files
export const filesFormsDownload = (filename: string) =>
	`${getAPIRoot()}/files/forms/${filename}`;
//Release notes
export const releaseNotes = () => `${getAPIRoot()}/release-notes`;

//Help
export const vidoesListURL = () => `${getAPIRoot()}/help/videos`;
//Account balance
export const accountBalanceTotalURL = () =>
	`${getAPIRoot()}/account-balance/total`;
export const accountBalanceHeaderURL = () =>
	`${getAPIRoot()}/account-balance/header`;
export const accountBalanceDetailURL = () =>
	`${getAPIRoot()}/account-balance/detail`;
export const accountBalanceTransactionHeaderURL = (member: string) =>
	`${getAPIRoot()}/account-balance/detail/${member}/transaction/header`;
export const accountBalanceTransactionDetailURL = (member: string) =>
	`${getAPIRoot()}/account-balance/detail/${member}/transaction/detail`;
// -----------------------------------------------------------------------------
// Registration Wizard
// -----------------------------------------------------------------------------
const registrationApiRoot = "/registration";
export const confirmInvitationApi = `${registrationApiRoot}/confirm-invitation`;
export const verifyMobileApi = `${registrationApiRoot}/verify-mobile`;
export const confirmMobileApi = `${registrationApiRoot}/confirm-mobile`;
export const verifyEmailApi = `${registrationApiRoot}/verify-email`;
export const confirmEmailApi = `${registrationApiRoot}/confirm-email`;
export const searchForApraFundApi = `${registrationApiRoot}/search-apra-fund`;
export const businessDetailsApi = `${registrationApiRoot}/business-details`;
export const defaultFundsApi = `${registrationApiRoot}/default-funds`;
export const supportEmailApi = `${registrationApiRoot}/support-email/`;

export const getRegistrationWizardSubmitApi = (invitationCode: string) =>
	`${registrationApiRoot}/submit/${invitationCode}`;

// -----------------------------------------------------------------------------
// Employer Registration
// -----------------------------------------------------------------------------
const employerRegistrationApiRoot = `${apiRoot}/employer-registration`;
export const employerRegistrationListApi = `${employerRegistrationApiRoot}/`;
export const getEmployerRegistrationDetailsApi = (
	employerRegistrationId: string
) => `${employerRegistrationApiRoot}/${employerRegistrationId}`;
export const getEmployerRegistrationApproveApi = (
	employerRegistrationId: string
) => `${getEmployerRegistrationDetailsApi(employerRegistrationId)}/approve`;
export const getEmployerRegistrationDeclineApi = (
	employerRegistrationId: string
) => `${getEmployerRegistrationDetailsApi(employerRegistrationId)}/decline`;
export const getPermissionCheckForEntity =
	getAPIRoot() + "/current_user/hasPermission";

export const upscopePublicKeyURL = (brand: string) => `/upscope/${brand}`;

export const getAllApraFunds = () => `${getAPIRoot()}/fund/getAllFunds`;
export const fileFormatOptions = () => `${getAPIRoot()}/file-format`;

export const fundFormatsAll = () => `${getAPIRoot()}/fund-format/mapping`;
export const fundFormatAndMappingsById = (id: number) =>
	`${getAPIRoot()}/fund-format/mapping/${id}`;
export const createFundFormatAndMappings = () =>
	`${getAPIRoot()}/fund-format/mapping`;
export const listAvailableFundFormat = () =>
	`${getAPIRoot()}/fund-format/available_format`;
export const addFundFormatListUrl = () => `${getAPIRoot()}/fund-format/add`;
export const fundFormatList = () => `${getAPIRoot()}/fund-format/list`;
export const fundFormatLinkDelete = () => `${getAPIRoot()}/fund-format/delete`;
export const mappingsByFundFormatId = (id: number) =>
	getAPIRoot() + `/fund-field-mapping/mapping/${id}`;

export const fileFormatMappingsAll = () =>
	`${getAPIRoot()}/file-format/mapping`;
export const fileFormatMappingById = (id: number) =>
	`${getAPIRoot()}/file-format/mapping/${id}`;
export const usiMappingById = (id: number) =>
	`${getAPIRoot()}/fund-field-mapping/mapping/${id}`;
export const usiMapping = () => getAPIRoot() + `/fund-field-mapping/mapping`;
export const saffColumnOptions = () =>
	`${getAPIRoot()}/file-format/saff-column`;
export const fileFormatMapping = () => `${getAPIRoot()}/file-format/mapping`;
export const fileFormatList = () => `${fileFormatOptions()}/list`;
export const fileFormatLinkDelete = () => `${fileFormatOptions()}/delete`;
export const listAvailableFileFormat = () =>
	`${fileFormatOptions()}/available_format`;
export const addFormatListUrl = () => `${fileFormatOptions()}/add`;

export const insight = (dashboard: string) =>
	`${getAPIRoot()}/insight/${dashboard}`;
//No employer selector
export const quicksightWidget = (dashboard: string) =>
	`${getAPIRoot()}/insight/quicksight/${dashboard}`;
//Employer selector required
export const quicksightDashboard = (dashboard: string) =>
	`${getAPIRoot()}/insight/quicksight-dashboard/${dashboard}`;
export const insightOneLiners = () => `${getAPIRoot()}/insight/one-liners`;

export const bulkFiles = () => `${getAPIRoot()}/bulk-file/`;
export const fileFormatForBulkFiles = (entity: string) =>
	`${getAPIRoot()}/bulk-file/file-format/${entity}`;
export const addBulkFile = (
	entity: string,
	fileFormatId: string,
	payeeRelationshipId: string
) =>
	`${getAPIRoot()}/bulk-file/${entity}/${fileFormatId}/${payeeRelationshipId}`;
export const deleteBulkFile = (id: number) => `${getAPIRoot()}/bulk-file/${id}`;
export const getBulkFileFunds = (entity: string) =>
	`${getAPIRoot()}/bulk-file/funds/${entity}`;
export const insightOneLinersForEntity = () =>
	`${getAPIRoot()}/insight/one-liners-for-entity`;
export const canUserSeeInsightsMenu = () =>
	`${getAPIRoot()}/insight/insights_menu_content`;

// Defined Benefit
export const definedBenefitEmployee = () =>
	`${getAPIRoot()}/defined-benefit/employee`;
export const definedBenefitTabDataForEmployee = (rcId: number, empId: number) =>
	definedBenefitEmployee() + `/tab-data/${rcId}/${empId}`;
export const definedBenefitFeaturesEnabled = () =>
	`${getAPIRoot()}/config/defined-benefits`;
export const fundMessageCategories = () =>
	`${getAPIRoot()}/fund-message-category`;
export const fundMessageConversation = () =>
	`${getAPIRoot()}/message/conversation`;
export const fundMessageMessage = () => `${getAPIRoot()}/message/message`;
export const fundMessageDone = () => `${getAPIRoot()}/message/done`;
export const messageAttachment = () => `${getAPIRoot()}/message/attachment/`;
export const postMessageAttachment = (entity: string) =>
	`${getAPIRoot()}/message/attachment/${entity}`;
export const getExtensions = () => `${getAPIRoot()}/message/extensions`;
