


























import { VNodeComponentOptions, VNodeData } from "vue";
import { Component, Prop } from "vue-property-decorator";
import Form from "@/form/Form.vue";
import { isArray } from "lodash";

@Component
export default class EmployeeForm extends Form {
	@Prop(String) highlightedFieldName!: string;

	@Prop({ type: Boolean, default: true })
	readonly errorsShownExternally!: boolean;

	@Prop(String) highlightType!: "" | "error" | "warning";

	protected applyExtraPropsToField(props: {
		[propName: string]: any | undefined;
	}) {
		props.errorsShownExternally = this.errorsShownExternally;
	}

	protected applyExtraListenersToField(
		componentOptions: VNodeComponentOptions
	) {
		const listeners = { ...componentOptions.listeners } as {
			[key: string]: any;
		};

		const emitClearErrorsWarnings: Function = () => {
			this.$emit(
				"clear-errors-warnings",
				(componentOptions.propsData as any).name
			);
		};

		// NOTE(Dhiraj, York): 2021-01-12
		// Do not add vue specific listener handler functions like "invoker"
		// to the list of listeners on a particular event as it can lead to
		// infinite calls and cause stack overflow
		if (!listeners.input) {
			listeners.input = [emitClearErrorsWarnings];
		} else {
			let inputListener: Function | Function[] =
				listeners.input.fns ?? listeners.input;
			if (!isArray(inputListener)) {
				inputListener = [inputListener];
			}
			if (
				!inputListener.find(
					(func) => func.name === "emitClearErrorsWarnings"
				)
			) {
				inputListener.push(emitClearErrorsWarnings);
			}
			listeners.input = inputListener;
		}

		componentOptions.listeners = listeners;
	}

	protected applyExtraVNodeDataToField(
		data: VNodeData,
		props: { [propName: string]: any | undefined }
	) {
		if (props.name === this.highlightedFieldName && this.highlightType) {
			let className = data.staticClass || "";
			className += ` EmployeeForm__field--${this.highlightType}`;
			data.staticClass = className;
		} else if (data.staticClass) {
			data.staticClass = data.staticClass.replace(
				"EmployeeForm__field--error",
				""
			);
			data.staticClass = data.staticClass.replace(
				"EmployeeForm__field--warning",
				""
			);
		}

		const highlightFieldOnMouseEnter: Function = () => {
			this.$emit("highlighted-field-change", (props as any).name);
		};
		const cancelHighlightFieldOnMouseLeave: Function = () => {
			this.$emit("highlighted-field-change", "");
		};

		// Native listeners have to be added this way.
		// Ref: https://github.com/vuetifyjs/vuetify/issues/9999#issuecomment-574556877
		const nativeListeners =
			({ ...data.on } as {
				[key: string]: Function | Function[];
			}) || {};

		nativeListeners.mouseenter = nativeListeners.mouseenter
			? [highlightFieldOnMouseEnter].concat(nativeListeners.mouseenter)
			: highlightFieldOnMouseEnter;
		nativeListeners.mouseleave = nativeListeners.mouseleave
			? [cancelHighlightFieldOnMouseLeave].concat(
					nativeListeners.mouseleave
			  ) // eslint-disable-line no-mixed-spaces-and-tabs
			: cancelHighlightFieldOnMouseLeave;

		data.on = nativeListeners;
	}
}
