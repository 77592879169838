







































































































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import { hasPermission } from "@/utils/PermissionUtils";
import Button from "@/form/Button.vue";
import Grid from "@/grid/Grid.vue";
import {
	ColDef,
	ColGroupDef,
	ICellRendererParamsTyped,
} from "ag-grid-community";
import GridActionsRenderer from "@/grid/GridActionsRenderer.vue";
import axios from "@/utils/ApiUtils";
import {
	fileFormatMapping,
	fileFormatMappingById,
	saffColumnOptions,
} from "@/constants/apiconstants";
import { toastErrorMessage, toastSuccessMessage } from "@/plugins/toasts";
import { parseErrorMessage } from "@/utils/ErrorUtils";
import TextField from "@/form/TextField.vue";
import SelectField from "@/form/SelectField.vue";
import { SelectOption } from "@/form/FieldOptions";

import {
	FileFormatResponse,
	FileFormat,
	FileFormatSaffColumnMapping,
	FileFormatSaffColumnHeaderMapping,
	fileFormatConfig,
	fileCategoryConfig,
	toFindDuplicates,
} from "./FileFormatMapping";

@Component({
	components: { Grid, Layout, Button, TextField, SelectField },
})
export default class FileMappingDetailsPage extends Vue {
	@Prop(String) fileFormatId!: string;

	readonly configFormats = fileFormatConfig;
	readonly configCategories = fileCategoryConfig;
	readonly nonStandardColumnTitle = "Non standard Saff column";

	private editAllowed = hasPermission("EDIT_FILE_MAPPING");
	private gridReady = false;
	private headerGridReady = false;
	private gridVMList: Vue[] = [];
	private gridHeaderVMList: Vue[] = [];

	private fileFormat: FileFormat | null = null;
	private fileFormatSaffColumnMappings: FileFormatSaffColumnMapping[] = [];
	private fileFormatSaffColumnHeaderMapping: FileFormatSaffColumnMapping[] =
		[];
	private headerGridErrors: string[] = [];

	private isContributionFileCategoryType = false;

	private readonly headerOptions: SelectOption[] = [
		{
			value: "0",
			label: "0",
		},
		{
			value: "1",
			label: "1",
		},
		{
			value: "2",
			label: "2",
		},
		{
			value: "3",
			label: "3",
		},
	];
	private saffColumnOptions: {
		name: string;
		type: string;
		nonStandard: boolean;
	}[] = [];
	private add = false;
	private headerSettingsEnabled = false;
	private hideFileNameMapping = true;

	public $refs!: {
		gridEl: Grid;
		columnHeaderMapping: Grid;
	};

	get formIsValid() {
		if (this.headerSettingsEnabled) {
			return !this.hasError && !this.headerHasErrors ? false : true;
		}
		return !this.hasError ? false : true;
	}

	// The rule "params.value.includes(".")" is so the user doesn't interfere with our column name delimiter
	private readonly columnDefs: (ColGroupDef | ColDef)[] = [
		{
			headerName: "Header",
			field: "headerName",
			width: 150,
			resizable: true,
			editable: this.editAllowed,
			cellClassRules: {
				"border border-danger": (params: any) => {
					return (
						!params.value ||
						params.value === "" ||
						params.value.length > 100 ||
						params.value.includes(".")
					);
				},
			},
			hide: true,
		},
		{
			headerName: "Sub Header",
			field: "subHeaderName",
			width: 150,
			resizable: true,
			editable: this.editAllowed,
			cellClassRules: {
				"border border-danger": (params: any) => {
					return (
						!params.value ||
						params.value === "" ||
						params.value.length > 100 ||
						params.value.includes(".")
					);
				},
			},
			hide: true,
		},
		{
			headerName: "Column name",
			field: "columnName",
			width: 50,
			resizable: true,
			editable: this.editAllowed,
			cellClassRules: {
				"border border-danger": (params: any) => {
					return (
						!params.value ||
						params.value === "" ||
						params.value.length > 100 ||
						params.value.includes(".")
					);
				},
			},
		},
		{
			headerName: "Type",
			field: "type",
			width: 50,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				values: [
					"Text",
					"Date",
					"Number",
					"Yes/No",
					"True/False",
					"RCID",
					"ABN",
					"Employer",
					"USI",
					"Ignore",
				],
			},
			resizable: true,
			editable: this.editAllowed,
			cellClassRules: {
				"border border-danger": (params: any) => {
					return !params.value || params.value === "";
				},
			},
		},
		{
			headerName: "SAFF column",
			field: "saffColumnIdName",
			width: 400,
			suppressSizeToFit: true,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: this.getSaffColumnOptions.bind(this),
			valueSetter: (params) => {
				if (params.newValue) {
					const id = params.newValue.split(" - ")[0];
					params.data.saffColumnId = id === "-" ? null : Number(id);
					params.data.saffColumnIdName = params.newValue;

					if (params.newValue.includes(this.nonStandardColumnTitle)) {
						this.$refs.gridEl.columnApi?.setColumnsVisible(
							["fieldNameMapping"],
							true
						);
						this.$refs.gridEl.sizeColumnsToFit();
					} else {
						params.data.fieldNameMapping = "";
						this.$refs.gridEl.columnApi?.setColumnsVisible(
							["fieldNameMapping"],
							false
						);

						this.$refs.gridEl.sizeColumnsToFit();
					}
					return true;
				}
				return false;
			},
			resizable: true,
			editable: this.editAllowed,
			cellClassRules: {
				"border border-danger": (params: any) => {
					return (
						!params.value ||
						params.value === "" ||
						params.data.duplicateSaffColumnId
					);
				},
			},
		},
		{
			headerName: "Field name mapping",
			field: "fieldNameMapping",
			width: 50,
			resizable: true,
			editable: this.editAllowed,
			hide: this.hideFileNameMapping,
			cellClassRules: {
				"border border-danger": (params: any) => {
					let saffColumnName = null;
					if (params.data && params.data.saffColumnIdName) {
						const saffColumnSplit =
							params.data.saffColumnIdName.split(" - ");
						if (saffColumnSplit.length === 3) {
							saffColumnName = saffColumnSplit[2];
						}
					}

					return (
						saffColumnName === this.nonStandardColumnTitle &&
						(!params.value ||
							params.value === "" ||
							params.value.length > 100 ||
							params.value.includes("."))
					);
				},
			},
		},
		{
			headerName: "Start offset",
			field: "startOffset",
			width: 100,
			resizable: true,
			editable: this.editAllowed,
			hide: !this.headerSettingsEnabled,
			cellClassRules: {
				"border border-danger": (params: any) => {
					return (
						!Number.isInteger(Number.parseInt(params.value)) ||
						params.value < 0
					);
				},
			},
		},
		{
			headerName: "End offset",
			field: "endOffset",
			width: 100,
			resizable: true,
			editable: this.editAllowed,
			hide: !this.headerSettingsEnabled,
			cellClassRules: {
				"border border-danger": (params: any) => {
					return (
						!Number.isInteger(Number.parseInt(params.value)) ||
						params.value < 0
					);
				},
			},
		},
		{
			headerName: "Actions",
			field: "__Actions",
			cellRenderer: this.actionsRender,
			width: 120,
			hide: !this.editAllowed,
			suppressSizeToFit: true,
		},
	];

	updatedHeaderCount() {
		if (this.fileFormat?.numHeaders === 3) {
			this.$refs.gridEl.columnApi?.setColumnVisible("headerName", true);
			this.$refs.gridEl.columnApi?.setColumnVisible(
				"subHeaderName",
				true
			);
			this.$refs.gridEl.sizeColumnsToFit();
		} else if (this.fileFormat?.numHeaders === 2) {
			this.$refs.gridEl.columnApi?.setColumnVisible("headerName", true);
			this.$refs.gridEl.columnApi?.setColumnVisible(
				"subHeaderName",
				false
			);
			this.$refs.gridEl.sizeColumnsToFit();
		} else {
			this.$refs.gridEl.columnApi?.setColumnVisible("headerName", false);
			this.$refs.gridEl.columnApi?.setColumnVisible(
				"subHeaderName",
				false
			);
			this.$refs.gridEl.sizeColumnsToFit();
		}
	}
	actionsRender(
		params: ICellRendererParamsTyped<FileFormatSaffColumnMapping>
	): HTMLElement {
		const vm = new Vue({
			el: document.createElement("div"),

			render: (createElement) => {
				return createElement(GridActionsRenderer, {
					props: {
						rowIndex: params.rowIndex,
						row: params.data,
						isDelete: true,
						isEdit: false,
					},
					on: {
						clickDelete: this.removeRow,
					},
				});
			},
		});
		this.gridVMList.push(vm);
		return vm.$el as HTMLElement;
	}

	private onGridReady() {
		if (this.fileFormat?.fileCategoryCode === "CONT") {
			this.isContributionFileCategoryType = true;
		}

		if (!this.isContributionFileCategoryType) {
			this.hideSaffColumnColumns();
		}

		if (!this.hideFileNameMapping) {
			this.showFieldNameMappingColumns();
		}

		this.gridReady = true;

		if (this.fileFormat?.fileFormatType === "FI") {
			this.showFIColumns();
		} else {
			this.hideFIColumns();
		}
		this.updatedHeaderCount();
	}

	private removeRow(param: {
		rowIndex: number;
		row: FileFormatSaffColumnMapping;
	}) {
		this.fileFormatSaffColumnMappings.splice(param.rowIndex, 1);
	}

	private getSaffColumnOptions() {
		return {
			values: this.saffColumnOptions.map((col) => col.name),
			cellRenderer: (params: { value: any }) => {
				return `<div style="display:inline-block;width:400px;">${params.value}</div>`;
			},
		};
	}

	private onAdd() {
		let prevHeader;
		let prevSubHeader;
		if (
			this.fileFormat?.numHeaders &&
			this.fileFormat.numHeaders > 1 &&
			this.fileFormatSaffColumnMappings.length > 0
		) {
			const prevEntry =
				this.fileFormatSaffColumnMappings[
					this.fileFormatSaffColumnMappings.length - 1
				];
			if (prevEntry.headerName !== undefined) {
				prevHeader = prevEntry.headerName;
			}
		}
		if (
			this.fileFormat?.numHeaders &&
			this.fileFormat.numHeaders > 2 &&
			this.fileFormatSaffColumnMappings.length > 0
		) {
			const prevEntry =
				this.fileFormatSaffColumnMappings[
					this.fileFormatSaffColumnMappings.length - 1
				];
			if (prevEntry.subHeaderName !== undefined) {
				prevSubHeader = prevEntry.subHeaderName;
			}
		}
		this.fileFormatSaffColumnMappings.push({
			id: 0,
			fileFormatId: Number(this.fileFormatId),
			columnName: "",
			type: "",
			validateOnLoad: false,
			saffColumnId: -1,
			saffColumnIdName: "",
			headerName: prevHeader,
			subHeaderName: prevSubHeader,
			fieldNameMapping: "",
			startOffset: 0,
			endOffset: 0,
		});
		this.$refs.gridEl.setRowData(this.fileFormatSaffColumnMappings);

		this.$refs.gridEl.paginationGoToPage(
			Math.ceil(
				this.fileFormatSaffColumnMappings.length /
					this.$refs.gridEl.paginationPageSize
			)
		);
	}

	mounted() {
		this.add = this.fileFormatId === "0";
		if (this.add) {
			this.fileFormat = {
				id: 0,
				fileFormatName: "",
				numRowSkip: 0,
				rowIdentifier: 0,
				numHeaders: 1,
				fileFormatType: null,
				fileCategoryCode: null,
			};
		} else if (this.fileFormatId) {
			axios
				.get<FileFormatResponse>(
					fileFormatMappingById(Number(this.fileFormatId))
				)
				.then((resp) => {
					this.fileFormatSaffColumnMappings = resp.data.mappings;
					this.fileFormat = resp.data.fileFormat;

					this.hideFileNameMapping =
						!this.fileFormatSaffColumnMappings.some((item) =>
							item.saffColumnIdName.includes(
								this.nonStandardColumnTitle
							)
						);

					if (resp.data.fileFormat.fileFormatType === "FI") {
						this.headerSettingsEnabled = true;
						this.fileFormatSaffColumnHeaderMapping =
							resp.data.headerMappings;
					} else {
						this.headerSettingsEnabled = false;
						this.fileFormatSaffColumnHeaderMapping = [];
					}
				})
				.catch((error) => {
					toastErrorMessage(parseErrorMessage(error));
				});
		}

		axios
			.get<[]>(saffColumnOptions())
			.then((resp) => {
				this.saffColumnOptions = resp.data;
			})
			.catch((error) => {
				toastErrorMessage(parseErrorMessage(error));
			});
	}

	onBack() {
		this.$router.push({
			name: this.editAllowed
				? "Configure file mappings"
				: "View file mappings",
		});
	}

	sanitiseSaffColumnValuesIfNonContribution() {
		if (!this.isContributionFileCategoryType) {
			for (let i = 0; i < this.fileFormatSaffColumnMappings.length; i++) {
				if (this.fileFormatSaffColumnMappings[i].saffColumnId == -1) {
					this.fileFormatSaffColumnMappings[i].saffColumnId = null;
					this.fileFormatSaffColumnMappings[i].saffColumnIdName = "-";
				}
			}
		}
	}

	onSave() {
		this.sanitiseSaffColumnValuesIfNonContribution();
		if (this.add) {
			this.checkHeaderMapping();
			axios
				.post<FileFormatResponse>(
					fileFormatMapping(),
					{
						fileFormat: this.fileFormat,
						mappings: this.fileFormatSaffColumnMappings,
						headerMappings: this.fileFormatSaffColumnHeaderMapping,
					},
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				)
				.then((resp) => {
					this.fileFormatSaffColumnMappings = resp.data.mappings;
					this.fileFormat = resp.data.fileFormat;
					this.fileFormatSaffColumnHeaderMapping =
						resp.data.headerMappings;
					this.add = false;
					toastSuccessMessage(
						"File format mapping saved successfully"
					);
					this.$router.push({
						name: "Configure file mapping details",
						params: {
							fileFormatId: String(this.fileFormat.id),
						},
					});
				})
				.catch((error) => {
					toastErrorMessage(parseErrorMessage(error));
				});
		} else {
			this.checkHeaderMapping();
			axios
				.put<FileFormatResponse>(
					fileFormatMappingById(Number(this.fileFormatId)),
					{
						fileFormat: this.fileFormat,
						mappings: this.fileFormatSaffColumnMappings,
						headerMappings: this.fileFormatSaffColumnHeaderMapping,
					},
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				)
				.then((resp) => {
					this.fileFormatSaffColumnMappings = resp.data.mappings;
					this.fileFormat = resp.data.fileFormat;
					if (resp.data.fileFormat.fileFormatType === "FI") {
						this.headerSettingsEnabled = true;
						this.fileFormatSaffColumnHeaderMapping =
							resp.data.headerMappings;
					}
					toastSuccessMessage(
						"File format mapping saved successfully"
					);
				})
				.catch((error) => {
					toastErrorMessage(parseErrorMessage(error));
				});
		}
	}

	get invalidHeaderName() {
		if (!this.fileFormat?.numHeaders) {
			return true;
		} else {
			if (this.fileFormat.numHeaders === 1) {
				return false;
			}
			return (
				this.fileFormatSaffColumnMappings.filter(
					(s) => !s.headerName || s.headerName === ""
				).length > 0
			);
		}
	}

	get invalidSubHeaderName() {
		if (!this.fileFormat?.numHeaders) {
			return true;
		} else {
			if (this.fileFormat.numHeaders <= 2) {
				return false;
			}
			return (
				this.fileFormatSaffColumnMappings.filter(
					(s) => !s.subHeaderName || s.subHeaderName === ""
				).length > 0
			);
		}
	}

	get columnNameNotSet() {
		return (
			this.fileFormatSaffColumnMappings.filter(
				(s) => !s.columnName || s.columnName === ""
			).length > 0
		);
	}

	get invalidColumnNameLength() {
		return (
			this.fileFormatSaffColumnMappings.filter(
				(s) => s.columnName && s.columnName.length > 100
			).length > 0
		);
	}

	get typeNotSet() {
		return (
			this.fileFormatSaffColumnMappings.filter((s) => s.type === "")
				.length > 0
		);
	}

	get saffColumnNotSet() {
		if (!this.isContributionFileCategoryType) {
			return false;
		} else {
			return (
				this.fileFormatSaffColumnMappings.filter(
					(s) => s.saffColumnId === -1
				).length > 0
			);
		}
	}

	get saffColumnIdDuplicate() {
		const arry = this.fileFormatSaffColumnMappings
			.filter(
				(s) =>
					s.saffColumnId !== null &&
					s.saffColumnId > 0 &&
					s.saffColumnIdName !== this.nonStandardSaffColumnIdName
			)
			.map((s) => s.saffColumnIdName);

		return toFindDuplicates(arry);
	}

	get isMappingEmpty() {
		return this.fileFormatSaffColumnMappings.length === 0;
	}

	get hasError() {
		return (
			this.columnNameNotSet ||
			this.typeNotSet ||
			this.saffColumnNotSet ||
			this.saffColumnIdDuplicate.length > 0 ||
			this.isMappingEmpty ||
			this.invalidHeaderName ||
			this.invalidSubHeaderName ||
			this.anyFieldNameMappingNotSet ||
			this.fieldNameMappingDuplicate.length > 0
		);
	}

	hideSaffColumnColumns() {
		this.$refs.gridEl.columnApi?.setColumnWidth("saffColumnIdName", 400);
		this.$refs.gridEl.columnApi?.setColumnsVisible(
			["saffColumnIdName"],
			false
		);
	}

	showSaffColumnColumns() {
		this.$refs.gridEl.columnApi?.setColumnWidth("columnName", 160);
		this.$refs.gridEl.columnApi?.setColumnWidth("saffColumnIdName", 400);
		this.$refs.gridEl.columnApi?.setColumnsVisible(
			["saffColumnIdName"],
			true
		);
	}

	emitUpdate(field: string, value: string) {
		if (field === "fileCategory" && this.fileFormat) {
			this.fileFormat.fileCategoryCode = value;

			if (this.fileFormat.fileCategoryCode == "CONT") {
				this.isContributionFileCategoryType = true;

				this.headerGridReady = false;
				this.onHeaderGridReady();

				this.showSaffColumnColumns();
			} else {
				this.isContributionFileCategoryType = false;

				this.headerGridReady = false;
				this.onHeaderGridReady();

				this.hideSaffColumnColumns();
			}
		}

		if (field === "fileFormatType") {
			// Coming here
			if (this.fileFormat) {
				this.fileFormat.fileFormatType = value;
			}

			if (this.fileFormat && this.fileFormat.fileFormatType === "FI") {
				this.headerSettingsEnabled = true;
				this.fileFormat.numHeaders = 1;
				this.fileFormat.numRowSkip = 0;
				this.showFIColumns();
				this.updatedHeaderCount();

				if (
					this.fileFormat.fileCategoryCode == "CONT" ||
					!this.fileFormat.fileCategoryCode
				) {
					this.isContributionFileCategoryType = true;

					this.showSaffColumnColumns();
				} else {
					// this.fileFormat.fileCategoryCod NOT DEFINED
					this.isContributionFileCategoryType = false;

					this.hideSaffColumnColumns();
				}
			} else {
				this.headerSettingsEnabled = false;
				this.hideFIColumns();
			}
		}

		this.$emit("change", { field: field, value: value });
	}

	showFIColumns() {
		this.$refs.gridEl.columnApi?.setColumnWidth("saffColumnIdName", 400);

		if (this.isContributionFileCategoryType) {
			this.$refs.gridEl.columnApi?.setColumnsVisible(
				["saffColumnIdName"],
				true
			);
		} else {
			this.$refs.gridEl.columnApi?.setColumnsVisible(
				["saffColumnIdName"],
				false
			);
		}

		this.$refs.gridEl.columnApi?.setColumnsVisible(
			["startOffset", "endOffset"],
			true
		);
		this.$refs.gridEl.columnApi?.setColumnWidth("startOffset", 110);
		this.$refs.gridEl.columnApi?.setColumnWidth("endOffset", 110);
	}

	hideFIColumns() {
		this.$refs.gridEl.columnApi?.setColumnWidth("saffColumnIdName", 400);
		this.$refs.gridEl.columnApi?.setColumnsVisible(
			["startOffset", "endOffset"],
			false
		);
	}

	showFieldNameMappingColumns() {
		this.$refs.gridEl.columnApi?.setColumnWidth("columnName", 400);
		this.$refs.gridEl.columnApi?.setColumnWidth("type", 200);
		this.$refs.gridEl.columnApi?.setColumnWidth("fieldNameMapping", 200);
		this.$refs.gridEl.columnApi?.setColumnsVisible(
			["fieldNameMapping"],
			true
		);
	}

	checkHeaderMapping() {
		if (this.fileFormat?.fileFormatType != "FI") {
			this.fileFormatSaffColumnHeaderMapping = [];
		}
	}

	private readonly headerColumnDefsWithoutSaff: (ColGroupDef | ColDef)[] = [
		{
			headerName: "Column name",
			field: "columnName",
			width: 160,
			resizable: true,
			editable: this.editAllowed,
			cellClassRules: {
				"border border-danger": (params: any) => {
					return (
						!params.value ||
						params.value === "" ||
						params.value.length > 100
					);
				},
			},
		},
		{
			headerName: "Type",
			field: "type",
			width: 50,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				values: [
					"Text",
					"Date",
					"Number",
					"Yes/No",
					"True/False",
					"RCID",
					"Employer",
					"USI",
					"Ignore",
				],
			},
			resizable: true,
			editable: this.editAllowed,
			cellClassRules: {
				"border border-danger": (params: any) => {
					return !params.value || params.value === "";
				},
			},
		},
		{
			headerName: "Start offset",
			field: "startOffset",
			width: 100,
			resizable: true,
			editable: this.editAllowed,
			cellClassRules: {
				"border border-danger": (params: any) => {
					return (
						!Number.isInteger(Number.parseInt(params.value)) ||
						params.value < 0
					);
				},
			},
		},
		{
			headerName: "End offset",
			field: "endOffset",
			width: 100,
			resizable: true,
			editable: this.editAllowed,
			cellClassRules: {
				"border border-danger": (params: any) => {
					return (
						!Number.isInteger(Number.parseInt(params.value)) ||
						params.value < 0
					);
				},
			},
		},
		{
			headerName: "Actions",
			field: "__Actions",
			cellRenderer: this.headerGridActionsRender,
			width: 60,
			hide: !this.editAllowed,
		},
	];

	// Header Mapping
	private readonly headerColumnDefs: (ColGroupDef | ColDef)[] = [
		{
			headerName: "Column name",
			field: "columnName",
			width: 160,
			resizable: true,
			editable: this.editAllowed,
			cellClassRules: {
				"border border-danger": (params: any) => {
					return (
						!params.value ||
						params.value === "" ||
						params.value.length > 100
					);
				},
			},
		},
		{
			headerName: "Type",
			field: "type",
			width: 100,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				values: [
					"Text",
					"Date",
					"Number",
					"Yes/No",
					"True/False",
					"RCID",
					"Employer",
					"USI",
					"Ignore",
				],
			},
			resizable: true,
			editable: this.editAllowed,
			cellClassRules: {
				"border border-danger": (params: any) => {
					return !params.value || params.value === "";
				},
			},
		},
		{
			headerName: "SAFF column",
			field: "saffColumnIdName",
			width: 400,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: this.getSaffColumnOptions.bind(this),
			valueSetter: (params) => {
				if (params.newValue) {
					const id = params.newValue.split(" - ")[0];
					params.data.saffColumnId = id === "-" ? null : Number(id);
					params.data.saffColumnIdName = params.newValue;
					return true;
				}
				return false;
			},
			resizable: true,
			editable: this.editAllowed,
			cellClassRules: {
				"border border-danger": (params: any) => {
					return (
						!params.value ||
						params.value === "" ||
						params.data.duplicateSaffColumnId
					);
				},
			},
		},
		{
			headerName: "Start offset",
			field: "startOffset",
			width: 100,
			resizable: true,
			editable: this.editAllowed,
			cellClassRules: {
				"border border-danger": (params: any) => {
					return (
						!Number.isInteger(Number.parseInt(params.value)) ||
						params.value < 0
					);
				},
			},
		},
		{
			headerName: "End offset",
			field: "endOffset",
			width: 100,
			resizable: true,
			editable: this.editAllowed,
			cellClassRules: {
				"border border-danger": (params: any) => {
					return (
						!Number.isInteger(Number.parseInt(params.value)) ||
						params.value < 0
					);
				},
			},
		},
		{
			headerName: "Actions",
			field: "__Actions",
			cellRenderer: this.headerGridActionsRender,
			width: 60,
			hide: !this.editAllowed,
		},
	];

	private onHeaderGridReady() {
		this.headerGridReady = true;
	}

	private onHeaderRowAdd() {
		this.fileFormatSaffColumnHeaderMapping.push({
			id: 0,
			fileFormatId: Number(this.fileFormatId),
			columnName: "",
			type: "",
			validateOnLoad: false,
			saffColumnId: -1,
			saffColumnIdName: "",
			startOffset: 0,
			endOffset: 0,
			fieldNameMapping: "",
		});
		this.$refs.columnHeaderMapping.setRowData(
			this.fileFormatSaffColumnHeaderMapping
		);
		this.$refs.columnHeaderMapping.paginationGoToPage(
			Math.ceil(
				this.fileFormatSaffColumnHeaderMapping.length /
					this.$refs.columnHeaderMapping.paginationPageSize
			)
		);
	}

	headerGridActionsRender(
		params: ICellRendererParamsTyped<FileFormatSaffColumnHeaderMapping>
	): HTMLElement {
		const vm = new Vue({
			el: document.createElement("div"),

			render: (createElement) => {
				return createElement(GridActionsRenderer, {
					props: {
						rowIndex: params.rowIndex,
						row: params.data,
						isDelete: true,
						isEdit: false,
					},
					on: {
						clickDelete: this.removeHeaderRow,
					},
				});
			},
		});
		this.gridHeaderVMList.push(vm);
		return vm.$el as HTMLElement;
	}

	private removeHeaderRow(param: {
		rowIndex: number;
		row: FileFormatSaffColumnHeaderMapping;
	}) {
		this.fileFormatSaffColumnHeaderMapping.splice(param.rowIndex, 1);
	}

	// header grid error handling

	get headerColumnNameNotSet() {
		return (
			this.fileFormatSaffColumnHeaderMapping.filter(
				(s) => !s.columnName || s.columnName === ""
			).length > 0
		);
	}

	get anyFieldNameMappingNotSet() {
		return (
			this.fileFormatSaffColumnMappings.filter((s) => {
				if (s.saffColumnIdName.includes(this.nonStandardColumnTitle)) {
					return !s.fieldNameMapping || s.fieldNameMapping === "";
				}
				return false;
			}).length > 0
		);
	}

	get invalidHeaderColumnNameLength() {
		return (
			this.fileFormatSaffColumnHeaderMapping.filter(
				(s) => s.columnName && s.columnName.length > 100
			).length > 0
		);
	}

	get headerColumnTypeNotSet() {
		return (
			this.fileFormatSaffColumnHeaderMapping.filter((s) => s.type === "")
				.length > 0
		);
	}

	get saffHeaderColumnNotSet() {
		return (
			this.fileFormatSaffColumnHeaderMapping.filter(
				(s) => s.saffColumnId === -1
			).length > 0
		);
	}

	get saffHeaderColumnIdDuplicate() {
		const arry = this.fileFormatSaffColumnHeaderMapping
			.filter(
				(s) =>
					s.saffColumnId !== null &&
					s.saffColumnId > 0 &&
					s.saffColumnIdName !== this.nonStandardSaffColumnIdName
			)
			.map((s) => s.saffColumnIdName);

		return toFindDuplicates(arry);
	}

	get fieldNameMappingDuplicate() {
		if (this.fileFormat?.fileFormatType === "FI") {
			return [];
		}
		const arry = this.fileFormatSaffColumnMappings
			.filter(
				(s) =>
					s.saffColumnIdName === this.nonStandardSaffColumnIdName &&
					s.fieldNameMapping?.length !== 0
			)
			.map((s) => s.fieldNameMapping);
		return toFindDuplicates(arry);
	}

	get isHeaderMappingEmpty() {
		return this.fileFormatSaffColumnMappings.length === 0;
	}

	get headerHasErrors() {
		return (
			this.headerColumnNameNotSet ||
			this.headerColumnTypeNotSet ||
			this.saffHeaderColumnNotSet ||
			this.saffHeaderColumnIdDuplicate.length > 0 ||
			this.isHeaderMappingEmpty
		);
	}

	private setErrors(error: string) {
		if (this.headerGridErrors.indexOf(error) === -1) {
			this.headerGridErrors.push(error);
		}
	}

	get nonStandardSaffColumnIdName() {
		return this.saffColumnOptions
			.filter((s) => s.nonStandard)
			.map((s) => s.name)[0];
	}
}
