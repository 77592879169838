import { CheckBoxGroupOption, SelectOption } from "@/form/FieldOptions";

export type ParentOrgDto = CompanyDetail & {
	authPreference: string;
	authorisationPreferenceOptions?: SelectOption[] | null;
};

export type CompanyDetail = {
	parentOrganisationId?: number;
	id?: number;
	abn: string;
	name: string;
	addressLine1: string;
	addressLine2: string;
	suburb: string;
	postCode: string;
	state: string;
	authContactName?: string;
	authContactPosition?: string;
	authContactEmail?: string;
	authContactMobile?: string;
	dateFormat?: string;
	reportingCentreIdentification?: string;
	paymentProfileExists?: boolean;
	canUserAddPaymentProfile?: boolean;
	insightsMenuEmployeeThresholdAttained?: boolean;
	overrideInsightsMenuEmployeeThreshold?: boolean;
	sponsor?: string;
	enableRefundsV2Report?: boolean;
	enableSplittingOfBatch?: boolean;
};

export type Field =
	| "abn"
	| "name"
	| "addressLine1"
	| "addressLine2"
	| "suburb"
	| "postCode"
	| "state"
	| "rcIdentificationStrategy"
	| "locationId"
	| "bsb"
	| "accountNumber";

export type UpdateFieldValue = {
	field: Field;
	value: string;
};

export interface HierarchiesToAccess {
	parentId: string;
	parentLabel: string;
	employerId?: string;
	employerLabel?: string;
	rcId?: string;
	rcLabel?: string;
}

export const RCStrategyTypes = {
	ABN_LC: "ABN_LC",
	ABN_BK: "ABN_BK",
	ABN: "ABN",
};

export type RCStrategy = "ABN_LC" | "ABN_BK" | "ABN";

export type ReportingCentreDetail = {
	employerId?: number;
	id?: number;
	name: string;
	addressLine1: string;
	addressLine2?: string;
	suburb: string;
	postcode: string;
	state: string;
	rcIdentificationStrategy: RCStrategy;
	locationId?: string;
	abn?: string;
	bsb?: string;
	accountNumber?: string;
	paymentProfileMemberId: string;
};

export type MaintenanceEntity = {
	id: string;
	label: string;
};

export type BasePaymentProfile = {
	nickname?: string;
	paymentMethod?: string;
	refundBankAccountName?: string;
	refundBsb?: string;
	refundBankAccountNo?: string;
};

export type PaymentProfile = {
	memberId: string;
	clientId: string;
	nickname: string;
	employerId: number;
	paymentMethod: string;
	refundBankAccountName: string;
	refundBsb: string;
	refundBankAccountNo: string;
	debitBankAccountName: string;
	debitBsb: string;
	debitBankAccountNo: string;
	debitLvtInProgress: boolean;
	eftBankAccountName: string;
	eftBsb: string;
	eftBankAccountNo: string;
	bpayBiller: string;
	bpayCustRef: string;
};

export type PaymentProfileRC = {
	paymentProfile: PaymentProfile;
	reportingCentres: string[];
};

export type RoleOption = CheckBoxGroupOption & {
	roleName: string;
	roleExclusive: boolean;
	hierarchyEnabled: boolean;
	hierarchySelected: boolean;
	hierarchyEnabledForEmployer: boolean;
	hierarchySelectedForEmployer: boolean;
	showSponsors: boolean;
	category: string;
};
